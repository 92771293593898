const menu = document.querySelector("img.banana-menu");
const bananaButton = document.querySelector("button.banana-button");

const year = document.querySelector(".year");
year.textContent = new Date().getFullYear() + ".";

menu.addEventListener("click", bananaToggle);
bananaButton.addEventListener("click", bananaToggle);
bananaButton.addEventListener("mousedown", bananize);
bananaButton.addEventListener("mouseup", bananize);

function bananaToggle() {
  menu.classList.toggle("banana-menu-visible");
}

function bananize() {
  bananaButton.classList.toggle("banana-button-pressed");
}
